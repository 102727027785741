import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import { Link } from "gatsby";

function Person({
  cardText,
  cardTitle,
  cardImage,
  cardRichText,
  cardLink,
  cardLinkExternal,
}) {
  if (cardLink || cardLinkExternal) {
    return (
      <>
        {cardLinkExternal ? (
          <a target="_blank" href={cardLinkExternal}>
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                {cardImage ? (
                  <div>
                    <GatsbyImage
                      className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                      image={getImage(cardImage.file)}
                      alt={cardImage.alternativeText}
                    />
                  </div>
                ) : null}
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900 font-heading">
                    {cardTitle}
                  </p>
                  <p className="mt-3 text-base text-fossil">
                    {/* {cardText} */}
                    <ReactMarkdown>{cardRichText}</ReactMarkdown>
                  </p>
                </div>
              </div>
            </div>
          </a>
        ) : null}

        {cardLink ? (
          <Link to={"/" + cardLink}>
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                {cardImage ? (
                  <div>
                    <GatsbyImage
                      className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                      image={getImage(cardImage.file)}
                      alt={cardImage.alternativeText}
                    />
                  </div>
                ) : null}
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900 font-heading">
                    {cardTitle}
                  </p>
                  <p className="mt-3 text-base text-fossil">
                    {/* {cardText} */}
                    <ReactMarkdown>{cardRichText}</ReactMarkdown>
                  </p>
                </div>
              </div>
            </div>
          </Link>
        ) : null}
      </>
    );
  } else {
    return (
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-shrink-0">
          {cardImage ? (
            <div>
              <GatsbyImage
                className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                image={getImage(cardImage.file)}
                alt={cardImage.alternativeText}
              />
            </div>
          ) : null}
        </div>
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <p className="text-xl font-semibold text-gray-900 font-heading">
              {cardTitle}
            </p>
            <p className="mt-3 text-base text-fossil">
              {/* {cardText} */}
              <ReactMarkdown>{cardRichText}</ReactMarkdown>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Person;
