import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation } from "swiper";

function LogoSlider({
  logoSliderTitle,
  buttonText,
  buttonLInk,
  logos,
  alternativeText,
}) {
  return (
    <div>
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <p className="text-center font-semibold  text-gray-800 tracking-wide text-xl  pb-4">
          {logoSliderTitle}
        </p>

        <Swiper
          spaceBetween={10}
          slidesPerGroup={2}
          // slidesPerView={4}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          {logos.map((logo, index) => (
            <SwiperSlide key={index} className="p-8 pl-16">
              {/* <SwiperSlide key={index} className="p-8 pl-16"></SwiperSlide> */}
              <GatsbyImage
                image={getImage(logo.file)}
                alt={logo.alternativeText}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <Link
              to={buttonLInk}
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-sunset hover:bg-red-400"
            >
              {buttonText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogoSlider;
