import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

function StatsStrip({
  statText1,
  statText2,
  statText3,
  statNumber1,
  statNumber2,
  statNumber3,
  statsTitle,
  statsLeadText,
  statsBgColor,
  statsTextColor,
  statImage1,
  statImage2,
  statImage3,
}) {
  return (
    <div className={"bg-" + statsBgColor + " pt-12 sm:pt-16"}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            {statsTitle}
          </h2>
          <p className="mt-3 text-xl text-white sm:mt-4">{statsLeadText}</p>
        </div>
      </div>
      <div className={"bg-" + statsBgColor + "mt-10 pb-12 sm:pb-16"}>
        <div className="relative">
          <div
            className={
              "bg-" + statsBgColor + "absolute inset-0 h-1/2 bg-opacity-30"
            }
          />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <dl className={"bg-" + statsBgColor + " sm:grid sm:grid-cols-3"}>
                <div className="flex flex-col p-6 text-center ">
                  <dt
                    className={"text-" + statsTextColor + " order-1 mt-2 p-3"}
                  >
                    {statImage1 ? (
                      <div>
                        <GatsbyImage
                          className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                          image={getImage(statImage1.file)}
                          alt={statImage1.alternativeText}
                        />
                      </div>
                    ) : null}
                  </dt>
                  <dt
                    className={
                      "text-" +
                      statsTextColor +
                      " order-3 mt-2 text-xl font-bold leading-6 uppercase font-heading"
                    }
                  >
                    {statText1}
                  </dt>
                  <dd className="order-2 text-5xl font-extrabold text-white font-heading">
                    {statNumber1}
                  </dd>
                </div>
                <div className="flex flex-col p-6 text-center ">
                  <dt
                    className={"text-" + statsTextColor + " order-1 mt-2 p-3"}
                  >
                    {statImage2 ? (
                      <div>
                        <GatsbyImage
                          className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                          image={getImage(statImage2.file)}
                          alt={statImage2.alternativeText}
                        />
                      </div>
                    ) : null}
                  </dt>
                  <dt
                    className={
                      "text-" +
                      statsTextColor +
                      " order-3 mt-2 text-xl font-bold leading-6 uppercase font-heading"
                    }
                  >
                    {statText2}
                  </dt>
                  <dd className="order-2 text-5xl font-extrabold text-white font-heading">
                    {statNumber2}
                  </dd>
                </div>
                <div className="flex flex-col p-6 text-center ">
                  <dt
                    className={"text-" + statsTextColor + " order-1 mt-2 p-3"}
                  >
                    {statImage3 ? (
                      <div>
                        <GatsbyImage
                          className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
                          image={getImage(statImage3.file)}
                          alt={statImage3.alternativeText}
                        />
                      </div>
                    ) : null}
                  </dt>
                  <dt
                    className={
                      "text-" +
                      statsTextColor +
                      " order-3 mt-2 text-xl font-bold leading-6 uppercase font-heading"
                    }
                  >
                    {statText3}
                  </dt>
                  <dd className="order-2 text-5xl font-extrabold text-white font-heading">
                    {statNumber3}
                  </dd>
                </div>
                {/* <div className="flex flex-col p-6 text-center">
                  <dt
                    className={
                      "text-" +
                      statsTextColor +
                      " order-2 mt-2 text-xl font-bold leading-6 uppercase font-heading"
                    }
                  >
                    {statText2}
                  </dt>
                  <dd className="order-1 text-5xl font-extrabold text-white font-heading">
                    {statNumber2}
                  </dd>
                </div> */}
                {/* <div className="flex flex-col p-6 text-center ">
                  <dt
                    className={
                      "text-" +
                      statsTextColor +
                      " order-2 mt-2 text-xl font-bold leading-6 uppercase font-heading"
                    }
                  >
                    {statText3}
                  </dt>
                  <dd className="order-1 text-5xl font-extrabold text-white font-heading">
                    {statNumber3}
                  </dd>
                </div> */}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatsStrip;
