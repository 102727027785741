import * as React from "react";

function PageHeading({ pageHeadingText }) {
  return (
    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="relative py-8 bg-white overflow-hidden">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mt-2 block text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl font-heading">
              {pageHeadingText}
            </span>
          </h1>
        </div>
      </div>
    </div>
  );
}

export default PageHeading;
