import * as React from "react";
import { Link } from "gatsby";

function CtaStrip({
  ctaLeadText,
  ctaBodyText,
  ctaStripTitle,
  ctaStripBgColor,
  ctaStripButtonText,
  ctaStripButtonLink,
  ctaStripButtonColor,
}) {
  return (
    <div className={"bg-" + ctaStripBgColor}>
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <p className="text-center font-bold  text-white tracking-wide text-2xl font-heading">
          {ctaStripTitle}
        </p>
        {ctaLeadText ? (
          <p className="text-center text-white tracking-wide text-l pt-2 font-heading font-semibold">
            {ctaLeadText}
          </p>
        ) : null}
        {ctaBodyText ? (
          <p className="text-center text-white tracking-wide text-l pt-2 font-body">
            {ctaBodyText}
          </p>
        ) : null}

        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <Link
              to={"/" + ctaStripButtonLink}
              className={
                "inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-" +
                ctaStripButtonColor
              }
            >
              {ctaStripButtonText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CtaStrip;
