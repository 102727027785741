import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

function Article({ title, image, shortText, altText, themeColor, slug }) {
  return (
    <div
      key={title}
      className="flex flex-col rounded-lg shadow-lg overflow-hidden"
    >
      {image ? (
        <div className={"flex-shrink-0 border-b-8 border-" + themeColor}>
          <GatsbyImage
            className="h-48 w-full object-cover"
            image={getImage(image.article.articleImage.file)}
            alt={altText}
          />
        </div>
      ) : null}

      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <Link to={"/" + slug} className="block mt-2">
            <p className="text-xl font-semibold text-gray-900 font-heading">
              {title}
            </p>
            <p className="mt-3 text-base text-gray-500 font-body">
              {shortText}
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Article;
