import * as React from "react";
import ReactMarkdown from "react-markdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

function PageImage({ pageBodyImage, pageImageCaption, finalpageImage }) {
  return (
    <div
      className={
        finalpageImage
          ? "mt-6 mb-12 relative bg-white overflow-hidden"
          : "mt-6 relative bg-white overflow-hidden"
      }
    >
      <div className="text-lg max-w-prose mx-auto">
        <figure>
          <GatsbyImage
            className="w-full rounded-lg"
            image={getImage(pageBodyImage.file)}
            alt={pageBodyImage.alternativeText}
          />
          <figcaption className="p-1 text-sm text-gray-600 font-body">
            {pageImageCaption}
          </figcaption>
        </figure>
      </div>
    </div>
  );
}

export default PageImage;
