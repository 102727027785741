import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

function Person({
  personName,
  personTitle,
  personBioText,
  personImage,
  theme,
}) {
  return (
    <div className="space-y-4">
      <div className="aspect-w-3 aspect-h-2">
        {personImage ? (
          <div>
            <GatsbyImage
              className="object-cover shadow-lg rounded-lg"
              image={getImage(personImage.file)}
              alt={personImage.alternativeText}
            />
          </div>
        ) : null}
      </div>
      <div className="text-md leading-6 font-medium space-y-1">
        <h3 className={theme ? "text-white" : "text-gray-900"}>{personName}</h3>

        <p className="text-lime">{personTitle}</p>
      </div>
      <div className="text-sm">
        <p className={theme ? "text-white" : "text-gray-500"}>
          {personBioText}
        </p>
      </div>
    </div>
  );
}

export default Person;
