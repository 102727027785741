import * as React from "react";
import ReactMarkdown from "react-markdown";

function PageBody({ pageBodyText, finalBodyText, noHeading }) {
  console.log(noHeading);

  return (
    <div
      className={
        finalBodyText
          ? "mt-3 mb-12 relative bg-white overflow-hidden"
          : "mt-3 relative bg-white overflow-hidden"
      }
    >
      <div
        className={
          noHeading
            ? "relative px-4 sm:px-6 lg:px-8"
            : "pt-8 relative px-4 sm:px-6 lg:px-8"
        }
      >
        <div className="text-lg max-w-prose mx-auto">
          <span className="mt-0 prose prose-lg text-fossil mx-auto">
            <ReactMarkdown>{pageBodyText}</ReactMarkdown>
          </span>
        </div>
      </div>
    </div>
  );
}

export default PageBody;
