import * as React from "react";
import ReactMarkdown from "react-markdown";

function PageLead({ pageLeadText }) {
  return (
    <div className="relative px-4 sm:px-6 lg:px-8">
      <div className="relative bg-white overflow-hidden">
        <div className="text-lg max-w-prose mx-auto">
          <span className="mt-0 text-xl text-fossil leading-8">
            <ReactMarkdown>{pageLeadText}</ReactMarkdown>
          </span>
        </div>
      </div>
    </div>
  );
}

export default PageLead;
