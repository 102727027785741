import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

function LinkedCard({
  linkedCardText,
  linkedCardPageLink,
  linkedCardThemeColor,
  linkedCardImage,
  linkedCardTitle,
}) {
  return (
    <div
      key={linkedCardImage}
      className="flex flex-col rounded-lg shadow-lg overflow-hidden"
    >
      {linkedCardImage ? (
        <div
          className={"flex-shrink-0 border-b-8 border-" + linkedCardThemeColor}
        >
          <GatsbyImage
            className="h-48 w-full object-cover"
            image={getImage(linkedCardImage.file)}
            alt={linkedCardImage.alternativeText}
          />
        </div>
      ) : null}

      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <Link to={"/" + linkedCardPageLink} className="block mt-2">
            <p className="text-xl font-semibold text-gray-900 font-heading">
              {linkedCardTitle}
            </p>
            <p className="mt-3 text-base text-gray-500 font-body">
              {linkedCardText}
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LinkedCard;
